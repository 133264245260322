import {Button, DatePicker, Form, Input, Select} from "antd";
import {useContext, useState} from "react";
import {request} from "../../../helpers/request";
import {validation} from "../../../helpers/validation";
import {GlobalContext} from "../../../App";
import {makeDate} from "../../../helpers/date/makeDate";
import moment from 'moment';

const {Option} = Select;

const MemberItem = ({member, add, flat, house, addReset}) => {
    const {name, phone, role, dateIn, dateOut, id} = member || {};
    const [nameVal, setNameVal] = useState(add ? "" : name);
    const [phoneVal, setPhoneVal] = useState(add ? "" : phone);
    const [roleVal, setRoleVal] = useState(add ? 0 : role);
    const [dateInVal, setDateInVal] = useState(add ? null : dateIn);
    const [dateOutVal, setDateOutVal] = useState(add ? null : dateOut);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState(false);

    const {setModalHandler, setModalOpen, setFound} = useContext(GlobalContext);

    const roles = ['Господар', "Орендар", "Гість"];

    const deleteHandler = () => {
        setModalOpen(true);
        setModalHandler({id, flat, house});
    }

    const saveHandler = async () => {
        setError(false);

        const validate = validation({
            nameVal,
            phoneVal,
            roleVal,
        });

        if (validate) {
            if (add) {
                const data = await request('/family', {
                    method: "PATCH",
                    body: JSON.stringify({
                        flat,
                        house,
                        memberToAdd: {
                            id: Math.ceil(Math.random() * 999999).toString(16),
                            name: nameVal,
                            phone: phoneVal,
                            role: roleVal,
                            dateIn: dateInVal,
                            dateOut: dateOutVal
                        }
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                setFound(prev => {
                    prev.members = data.members;
                    return prev;
                });

                addReset();
            } else if (edit) {
                const data = await request('/family', {
                    method: "PATCH",
                    body: JSON.stringify({
                        flat,
                        house,
                        memberToEdit: {
                            id,
                            name: nameVal,
                            phone: phoneVal,
                            role: roleVal,
                            dateIn: dateInVal,
                            dateOut: dateOutVal
                        }
                    }),
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                if (data.error) {

                } else {
                    setEdit(false);
                    setFound(data);
                }
            }
        } else {
            setError(true);
        }
    }

    return edit || add ? (
        <Form
            layout="vertical"
            className="user-card"
        >
            {error ? <span className="error">Перевірте будь ласка правильність даних.</span> : null}
            <Form.Item
                label="Ім'я (мін. 5)"
            >
                <Input
                    value={nameVal}
                    onChange={(e) => setNameVal(e.target.value)}
                    readOnly={add ? false : !edit}
                />
            </Form.Item>
            <Form.Item
                label="Телефон (380505600715)"
            >
                <Input
                    value={phoneVal}
                    onChange={(e) => setPhoneVal(e.target.value)}
                    readOnly={add ? false : !edit}
                />
                {add || edit ? null : <a href={`tel:${phone}`}>Подзвонити</a>}
            </Form.Item>
            <Form.Item
                label="Статус"
            >
                <Select
                    value={roleVal}
                    onChange={(val) => setRoleVal(val)}
                    disabled={add ? false : !edit}
                >
                    <Option value={0}>Господар</Option>
                    <Option value={1}>Орендар</Option>
                    <Option value={2}>Гість</Option>
                </Select>
            </Form.Item>
            {roleVal === 2 ? <>
                <Form.Item
                    label="Дата заїзду"
                >
                    <DatePicker
                        defaultValue={moment(dateInVal)}
                        onChange={(date) => setDateInVal(date._d)}
                        disabled={add ? false : !edit}
                        format='DD.MM.YYYY'
                    />
                </Form.Item>
                <Form.Item
                    label="Дата виїзду"
                >
                    <DatePicker
                        defaultValue={moment(dateOutVal)}
                        onChange={(date) => setDateOutVal(date._d)}
                        disabled={add ? false : !edit}
                        format='DD.MM.YYYY'
                    />
                </Form.Item>
            </> : null}
            {add || edit ? <Button type="primary" onClick={saveHandler}>Зберегти</Button> : null}
            {add || edit ? <Button type="primary" onClick={() => {
                edit ? setEdit(false) : addReset();
            }}>Відміна</Button> : null}
        </Form>
    ) : <div className="user-card">
        <h3>{name} - {roles[role]}</h3>
        {role === 2 ? <>
            {makeDate(dateIn)}-
            {makeDate(dateOut)}
        </> : null}
        <h4><a href={`tel:${phone}`}>{phone}</a></h4>
        <Button type="primary" onClick={() => setEdit(true)}>Редагувати</Button>
        <Button type="primary" onClick={deleteHandler}>Видалити</Button>
    </div>
}

export default MemberItem;