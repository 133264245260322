export const request = async (url, options = {}) => {
    try {
        const res = await fetch('https://guard.family-finance.com.ua' + url, options);
        return await res.json();
    } catch (err) {
        return {
            error: true,
            message: err
        }
    }
}