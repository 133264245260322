import {createContext, useState} from "react";
import LaModalSearchResult from "./components/LaModal/LaSearchModal/LaModalSearchResult";
import LaSearchModal from "./components/LaModal/LaSearchModal";
import LaModal from "./components/LaModal";

export const GlobalContext = createContext({});

function App() {
    const [found, setFound] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalHandler, setModalHandler] = useState(false);

    const closeModal = () => {
        setModalOpen(false);
    }

    return (
        <GlobalContext.Provider value={{
            setModalHandler,
            setModalOpen,
            setFound,
            found
        }}>
            <div className="App">
                <div className="search-box">
                    <LaSearchModal setFound={setFound} closeModal={closeModal}/>
                </div>
                <LaModal modalOpen={modalOpen} setModalOpen={setModalOpen} modalHandler={modalHandler}/>
                {found ? <LaModalSearchResult result={found} setModalHandler={setModalHandler}/> : null}
            </div>
        </GlobalContext.Provider>
    );
}

export default App;
