export const makeDate = (date) => {
    if (date) {
        const _date = new Date(date);

        return `${_date.getDate()}.${_date.getMonth() + 1}.${_date.getFullYear()}`
    } else {
        return null;
    }
}

export const datePickerFormat = (date) => {
    const _date = new Date(date);

    return `${_date.getDate()}.${_date.getMonth() + 1}.${_date.getFullYear()}`
}