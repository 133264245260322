import MemberItem from "./MemberItem";
import {Button} from "antd";
import {useState} from "react";

const MemberList = ({result}) => {
    const list = result.members.map((member, i) => <MemberItem key={i} member={member} flat={result.flat} house={result.house}/>);

    const [add, setAdd] = useState(false);

    const handler = () => {
        setAdd(true);
    }

    const reset = () => {
        setAdd(false);
    }

    return (
        <div className="member-list">
            {list}
            {add ? null : <Button type="primary" onClick={handler} disabled={add} className="add-member">+</Button>}
            {add ? <MemberItem add flat={result.flat} house={result.house} addReset={reset}/> : null}
        </div>
    )
}

export default MemberList;