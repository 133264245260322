import {Button, Form, InputNumber, Select} from "antd";
import {useState} from "react";
import {request} from "../../../helpers/request";

const {Option} = Select;

const LaSearchModal = ({setFound, closeModal}) => {
    const [flat, setFlat] = useState(1);
    const [house, setHouse] = useState(13);
    const [disabled, setDisabled] = useState(false);

    const searchFunc = async () => {
        const data = await request(`/family?flat=${flat}&house=${house}`);

        if (data) {
            setFound(data);
            closeModal();
        }
    }

    return (
        <div>
            <div>
                <Form layout="vertical" className="search-form">
                    <Form.Item
                        label="Квартира"
                    >
                        <InputNumber min={1} max={210} value={flat} onChange={val => setFlat(val)}/>
                    </Form.Item>
                    <Form.Item
                        label="Будинок"
                    >
                        <Select value={house} onChange={(val) => setHouse(val)}>
                            <Option value={13}>13</Option>
                            <Option value={15}>15</Option>
                        </Select>
                    </Form.Item>

                    {/*<Form.Item*/}
                    {/*    label="Номер авто"*/}
                    {/*>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item*/}
                    {/*    label="Ім'я"*/}
                    {/*>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                </Form>
                <Button disabled={disabled} onClick={searchFunc} type="primary">Пошук</Button>
            </div>
        </div>
    )
}

export default LaSearchModal;