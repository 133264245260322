import MemberList from "../../../MemberList";

const LaModalSearchResult = ({result}) => {
    const {flat, house} = result;
    return (
        <div className="search-result">
            <h2>Кв. {flat}, буд. {house}</h2>
            <MemberList result={result}/>
        </div>
    )
}

export default LaModalSearchResult;