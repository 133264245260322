import {Button, Modal} from "antd";
import {request} from "../../helpers/request";
import {useContext} from "react";
import {GlobalContext} from "../../App";

const LaModal = ({modalOpen, setModalOpen, modalHandler}) => {
    const closeModal = () => setModalOpen(false);

    const {setFound} = useContext(GlobalContext);

    const okHandler = async () => {
        const {id, house, flat} = modalHandler;
        if (modalHandler.id) {
            const data = await request('/family', {
                method: "PATCH",
                body: JSON.stringify({
                    flat,
                    house,
                    member_to_delete: id
                }),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (data.error) {

            } else {
                setFound(prev => {
                    prev.members = prev.members.filter(member => member.id !== modalHandler.id);
                    return prev;
                });

                closeModal();
            }
        } else {

        }
    }

    return (
        <Modal visible={modalOpen} onCancel={closeModal} centered footer={false}>
            <h2>Видалити?</h2>
            <Button onClick={okHandler}>Так</Button>
            <Button onClick={closeModal}>Ні</Button>
        </Modal>
    )
}

export default LaModal;